import { getFilteredPermissionData } from '..';
import { PERMISSION_LIST } from '../../constants/common';

export const checkRoutes = (list: any, userAuthList: any) => {
  const nonFieldLevelPermissionsGroups = getFilteredPermissionData(
    userAuthList?.auth?.grantedPolicies ?? {}
  );

  const allPermissionList = PERMISSION_LIST.map((item) => item.id);
  const filterList = Object.keys(nonFieldLevelPermissionsGroups)
    .map((item) => item)
    .sort((a, b) => allPermissionList.indexOf(a) - allPermissionList.indexOf(b));

  return filterList.length > 0
    ? list
        .filter((list: any) => filterList.includes(list.pathname))
        .sort(
          (a: any, b: any) =>
            allPermissionList.indexOf(a.pathname) - allPermissionList.indexOf(b.pathname)
        )
    : list;
};
export const addStaticRoutes = (routeList: any, filteredList: any) => {
  // Extract static items from the original routeList
  const staticItems = routeList.filter((item: any) => item.type === 'static');

  // Prepend static items to the filtered list
  const combinedList = staticItems.concat(filteredList);
  return combinedList;
};

export const checkChildrenRoute = (mainRoute: string, list: any, userAuthList: any) => {
  const nonFieldLevelPermissionsGroups = getFilteredPermissionData(
    userAuthList?.auth?.grantedPolicies ?? {}
  );
  const chiildRoutes = nonFieldLevelPermissionsGroups[`${mainRoute}`]?.map((item) => item?.text);

  return chiildRoutes?.length > 0
    ? list.filter((list: any) => chiildRoutes?.includes(list?.permission))
    : list;
};
