import {
  AuditOutlined,
  ContainerOutlined,
  DashboardOutlined,
  DesktopOutlined,
  FileTextOutlined,
  PieChartOutlined,
  UserOutlined,
} from '@ant-design/icons';

// Global font size
export const DEFAULT_FONT_SIZE = 10;

export const SIDEBAR_ROUTES = [
  {
    // route: '/terminology',
    name: 'Terminology',
    pathname: 'Terminology',
    icon: <PieChartOutlined />,
    children: [
      {
        route: '/terminology',
        name: 'Terminology List',
        pathname: 'Terminology Listings',
        permission: 'Default',
        icon: <PieChartOutlined />,
      },
      {
        route: '/terminology-approvers',
        name: 'Terminology Approval',
        pathname: 'Terminology Approvers',
        permission: 'Approver',
        icon: <PieChartOutlined />,
      },
      {
        route: '/terminology/bulk-actions',
        name: 'Terminology Bulk Actions',
        pathname: 'Terminology Bulk Actions',
        permission: 'BulkUpload',
        icon: <PieChartOutlined />,
      },
    ],
  },
  {
    name: 'Taxonomy',
    pathname: 'Taxonomy',
    icon: <DesktopOutlined />,
    children: [
      {
        route: '/taxonomy',
        name: 'Taxonomy List',
        pathname: 'Taxonomy Listings',
        permission: 'Default',
        icon: <DesktopOutlined />,
      },
      {
        route: '/taxonomy-approver',
        name: 'Taxonomy Approval',
        pathname: 'Taxonomy Approve',
        permission: 'Approver',
        icon: <DesktopOutlined />,
      },
      {
        route: '/taxonomy/hierarchy',
        name: 'Codex',
        pathname: 'Codex',
        permission: 'Default',
        icon: <DesktopOutlined />,
      },
      {
        route: '/taxonomy/bulk-actions',
        name: 'Taxonomy Bulk Actions',
        pathname: 'Taxonomy Bulk Actions',
        permission: 'BulkUpload',
        icon: <DesktopOutlined />,
      },
    ],
  },
  {
    route: '/role-management',
    name: 'Role Management',
    pathname: 'Role',
    icon: <ContainerOutlined />,
  },
  {
    route: '/user-management',
    name: 'User Management',
    pathname: 'User',
    icon: <UserOutlined />,
  },
  {
    route: '/dashboard',
    name: 'Dashboard',
    // pathname: 'Dashboard',
    // permission: 'Default',
    icon: <DashboardOutlined />,
    type: 'static',
  },

  // Application Rule
  {
    route: '/application',
    name: 'Application Management',
    icon: <FileTextOutlined />,
    pathname: 'Application',
  },

  // Rule Management
  {
    route: '/rule-management',
    name: 'Master Data Management',
    icon: <AuditOutlined />,
    pathname: 'Rule',
  },
];

export const PERMISSION_LIST = [
  {
    id: 'User',
    label: 'User Management',
  },
  {
    id: 'Role',
    label: 'Role Management',
  },
  {
    id: 'Terminology',
    label: 'Terminology Management',
  },
  {
    id: 'Taxonomy',
    label: 'Taxonomy Management',
  },
  {
    id: 'Taxonomy Approver',
    label: 'Taxonomy Management',
  },

  {
    id: 'Application',
    label: 'Application Management',
  },

  {
    id: 'Rule',
    label: 'Master Data Management',
  },
  {
    id: 'Dashboard',
    label: 'Dashboard',
  },
];

export const DATE_FORMAT = 'YYYY-MM-DD';

export const ACTIVE_INACTIVE_OPTIONS = [
  { label: 'Active', value: 'true' },
  { label: 'Inactive', value: 'false' },
];
export const ACTIVE_INACTIVE_OPTIONS_BOOLEAN = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
];

export const ROLES_PERMISSION_LIST = ['View', 'Create', 'Edit', 'Deactivate', 'Approver'];

export const ACTION_CONSTANTS_VIEW = 'VIEW';
export const ACTION_CONSTANTS_EDIT = 'EDIT';
export const ACTION_CONSTANTS_AUDITLOG = 'AUDITLOG';
export const ACTION_CONSTANTS_DEACTIVATE = 'DEACTIVATE';
export const ACTION_CONSTANTS_REMARK = 'REMARK';
export const ACTION_CONSTANTS_COMPARE = 'COMPARE';
export const STATIC_CONSTANTS_MENU = 'static';

export const RELOAD_PERMISSION_MESSAGE =
  'Your permission has changed. The current page will be refreshed to load your new permissions.';
export const REDIRECT_PERMISSION_MESSAGE =
  'Your permission has changed. You currently do not have permission to this features. Please work with your access control team if you would like to have access to this feature.';
