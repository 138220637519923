import { notification } from 'antd';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectToken } from '../auth/authSlice';
import { ApiError, OpenAPI } from '../services/codex-services';

export function useCodexApi(): {
  dismissError: () => void;
  error: ApiError | undefined;
  isLoading: boolean;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
} {
  const token = useSelector(selectToken);
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  OpenAPI.BASE = window.__RUNTIME_CONFIG__?.VITE_BASE_URL ?? '';
  OpenAPI.TOKEN = token?.toString();

  const handleRequest = useCallback(async function <T>(request: Promise<T>) {
    setIsLoading(true);
    try {
      const response = await request;
      setError(undefined);
      return response;
    } catch (exception: any) {
      setError(exception);
      if (exception?.status === 403 && exception?.body?.error?.validationErrors !== null) {
        return undefined;
      } else if (exception?.status === 401) {
        return undefined;
      } else if (exception?.body !== '') {
        notification.error({
          message: exception?.body?.error?.message,
        });
      } else if (exception?.message === 'Forbidden') {
        notification.error({
          message: 'Your request is Forbidden!',
        });
      } else if (exception.message === 'Network Error') {
        notification.error({
          message: 'Unable to fetch api',
        });
      } else if (exception?.body?.error?.validationErrors !== null) {
        notification.error({
          message: 'Your request is not valid!',
        });
      } else if (exception.message !== null) {
        notification.error({
          message: exception.message,
        });
      } else {
        notification.error({
          message: 'Unable to fetch api',
        });
      }
      throw new Error(exception);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const dismissError = (): void => {
    setError(undefined);
  };

  return { dismissError, error, isLoading, handleRequest };
}

export default useCodexApi;
