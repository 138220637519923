import styled from 'styled-components';
import { COLORS } from '../../constants/style/colors';

export const TaxonomyContainer = styled.div`
  .field-wrapper {
    margin-bottom: 20px;
    width: 100%;

    &--input {
      margin-bottom: 0px;
    }
  }

  .ant-select-item-option {
    padding: 0;
  }

  .form-required {
    position: relative;

    &::before {
      display: inline-block;
      margin-inline-end: 4px;
      color: ${COLORS.Red};
      font-size: 16px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
`;

export const CustomTable = styled.table`
  width: 100%;
  padding: 4px 12px;
  background-color: ${COLORS.FrostBlue};
  border-radius: 4px 4px 0 0;

  &.option {
    padding: 0;
    background-color: transparent;
  }

  th,
  td {
    width: calc(100% / 3);
    text-align: left;
  }

  td.ant-typograph {
    color: inherit;
  }
`;

export const CustomTablePagination = styled.div`
  display: flex;
  justify-content: flex-end;

  padding: 5px 0;
  box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.1);
`;

export const CodexTreeWrapper = styled.div`
  .ant-tree-node-content-wrapper {
    flex-grow: 1;
  }
`;
