import { lazy } from 'react';

export const RouteList = [
  {
    path: '/terminology-approvers',
    component: lazy(async () => await import('../pages/terminology/Terminology-approvers')),
    pathname: 'Terminology',
  },
  {
    path: '/terminology',
    component: lazy(async () => await import('../pages/terminology')),
    pathname: 'Terminology',
  },
  {
    path: '/terminology/audit-log/:id',
    component: lazy(async () => await import('../pages/terminology/audit-log')),
    pathname: 'Terminology',
  },
  {
    path: '/terminology/compare/:id',
    component: lazy(async () => await import('../pages/terminology/compare')),
    pathname: 'Terminology',
  },
  {
    path: '/terminology/bulk-actions',
    component: lazy(async () => await import('../pages/terminology/BulkActions')),
    pathname: 'Terminology',
  },
  {
    path: '/taxonomy',
    component: lazy(async () => await import('../pages/taxonomy')),
    pathname: 'Taxonomy',
  },
  {
    path: '/taxonomy-approver',
    component: lazy(async () => await import('../pages/taxonomy/taxonomy-approvers')),
    pathname: 'Taxonomy',
  },
  {
    path: '/taxonomy/audit-log/:id',
    component: lazy(async () => await import('../pages/taxonomy/audit-log')),
    pathname: 'Taxonomy',
  },
  {
    path: '/taxonomy/hierarchy',
    component: lazy(async () => await import('../pages/taxonomy/hierarchy')),
    pathname: 'Taxonomy',
  },
  {
    path: '/taxonomy/compare/:id',
    component: lazy(async () => await import('../pages/taxonomy/compare')),
    pathname: 'Taxonomy',
  },
  {
    path: '/taxonomy/bulk-actions',
    component: lazy(async () => await import('../pages/taxonomy/BulkActions')),
    pathname: 'Taxonomy',
  },
  {
    path: '/user-management',
    component: lazy(async () => await import('../pages/user-management')),
    pathname: 'User',
  },
  {
    path: '/role-management',
    component: lazy(async () => await import('../pages/role-management')),
    pathname: 'Role',
  },
  {
    path: '/dashboard',
    component: lazy(async () => await import('../pages/dashboard')),
    // pathname: 'Dashboard',
    type: 'static',
  },
  {
    path: '/rule-demo',
    component: lazy(async () => await import('../pages/rule-implementation')),
    type: 'static',
  },
  {
    path: '/page-not-found',
    component: lazy(async () => await import('../pages/pageNotFound')),
    pathname: 'pageNotFound',
  },

  {
    path: '/application',
    component: lazy(async () => await import('../pages/application')),
    pathname: 'Application',
  },
  {
    path: '/rule-management',
    component: lazy(async () => await import('../pages/rule-management')),
    pathname: 'Rule',
  },
];
