import { createGlobalStyle } from 'styled-components';
import { COLORS } from './constants/style/colors';

export default createGlobalStyle`
  *,
  *::before,
  *::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.Blue};
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
th{
  white-space: nowrap;
}
  th.ant-table-cell {

    svg {
      path{
        fill: white;
        transition: all .3s ease;
      }
    }
  }

  .ant-table{
    overflow-x: scroll;
  }

  th.ant-table-cell {
    .ant-table-column-sorter-down.active, .ant-table-column-sorter-up.active {
      svg {
        path{
          fill: transparent;
        }
      }
    }
  }

  // .ant-drawer-body {
  //   padding: 0 !important;
  // }

  // .drawer-body {
  //   height: calc(100vh - 59px - 73px);
  //   overflow-y: auto;
  //   padding: 24px;
  // }

  // .drawer-footer {
  //   box-sizing: border-box;
  //   width: 100%;
  //   right: 0px;
  //   bottom: 0px;
  //   border-top: 0.1rem solid rgb(237, 237, 237);
  //   background-color: rgb(255, 255, 255);
  //   padding: 20px 24px;

  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  // }

  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  // }

  // .ant-notification-notice-message {
  //   margin-bottom: 0 !important;
  // }

`;
