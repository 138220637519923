import { theme } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/authService';
import { Spin } from 'antd';

const { useToken } = theme;
function Login(): JSX.Element {
  const { token } = useToken();
  const authService = new AuthService();
  const navigate = useNavigate();

  useEffect(() => {
    if (authService.isAuthenticated()) {
      navigate('/');
    } else {
      authService.signInRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin tip='Loading....' size='large' fullscreen style={{ backgroundColor: token.colorPrimary }}>
      <div className='content' />
    </Spin>
  );
}

export default Login;
