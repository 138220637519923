export const COLORS = {
  Purple: "#7B53D1",
  Blue: '#345a7e',
  LightBlue: '#c1d9ec',
  LightPurple: '#F0E9FF',
  White: '#ffffff',
  Black: '#000000',
  FrostBlue: '#C1D9EC',
  Red: '#ff4d4f',
  Green: '#13A83D',
  LightRed: ' #ffc9bb',
  LightGreen: ' #cefad0',


  Silver: '#717171',
  SpanishGray: '#969696',
  OffWhite: '#F3F3F3',
  Gray: '#595959',
};
