import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Callback from '../auth/callback';
import Login from '../auth/login';
import { SIDEBAR_ROUTES } from '../constants/common';
import { selectAuthRoutesList } from '../pages/configuration/routeSlice';
import { addStaticRoutes, checkRoutes } from '../utils/permission/checkRoute';
import ProtectedRoute from './protectedRoute';
import { RouteList } from './routesList';
import { Spin } from 'antd';

interface RoutesContainerType {
  isAuthenticated: boolean;
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedKey: React.Dispatch<React.SetStateAction<string>>;
}

const RoutesContainer = ({
  isAuthenticated,
  collapsed,
  setCollapsed,
  setSelectedKey,
}: RoutesContainerType): JSX.Element => {
  const userAuthList = useSelector(selectAuthRoutesList);
  const authRoutes = checkRoutes(RouteList, userAuthList);
  const allRoutes = addStaticRoutes(RouteList, authRoutes);
  console.log(allRoutes, 'authroutes');
  useEffect(() => {
    const selectedKey = SIDEBAR_ROUTES.find((item) => item.route === location.pathname);
    setSelectedKey(selectedKey?.pathname || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Suspense fallback={<Spin />}>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/callback' element={<Callback />} />
        <Route
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
          }>
          {allRoutes.map(
            (
              route: {
                path: string;
                component: React.LazyExoticComponent<() => JSX.Element>;
                pathname: string;
                exact: boolean;
              },
              i: number
            ) => (
              <Route path={route.path} element={<route.component />} key={i} />
            )
          )}
          <Route
            path='/'
            element={
              <Navigate
                to={
                  Object.keys(userAuthList).length > 0 && allRoutes.length > 0 && allRoutes[0].path
                }
                replace
              />
            }
          />
          <Route path='*' element={<Navigate to='/page-not-found' replace />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutesContainer;
