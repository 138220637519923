import { Navigate, Outlet } from 'react-router-dom';
import PageLoading from '../components/loader/Loading';

interface ProtectedRouteType {
  isAuthenticated: boolean | object;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const ProtectedRoute = ({ isAuthenticated }: ProtectedRouteType) => {
  if (!isAuthenticated) {
    return (
      <>
        <PageLoading />
        <Navigate to='/login' />
      </>
    );
  }
  return <>{isAuthenticated && <Outlet />}</>;
};

export default ProtectedRoute;
