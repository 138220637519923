import { Col, Row, Spin } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../auth/authSlice';
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import { ProtectedRouterWrapper } from '../constants/style/common.styled';
import RoutesContainer from '../routes/routesContainer';
import AuthService from '../services/authService';
import PageLoading from '../components/loader/Loading';

const RouteMain = ({ loading }: { loading: boolean | null }) => {
  const authService = new AuthService();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState('');
  const isAuthenticated = useSelector(selectIsAuthenticated) || authService.isAuthenticated();

  useEffect(() => {
    const handleResize = () => {
      // Update the collapsed state based on the window width
      setCollapsed(window.innerWidth < 1024);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    setCollapsed(window.innerWidth < 1024);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loading || loading === null) {
    return <PageLoading />;
  }
  return (
    <Fragment>
      {isAuthenticated && (
        <Header
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          isAuthenticated={isAuthenticated}
        />
      )}
      <Col span={24}>
        <Row gutter={0} wrap={false}>
          {isAuthenticated && <Sidebar collapsed={collapsed} selectedKey={selectedKey} />}

          <div
            style={{
              flexGrow: '1',
              maxWidth: '100%',
              overflow: 'auto',
            }}>
            <ProtectedRouterWrapper>
              <RoutesContainer
                isAuthenticated={isAuthenticated}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                setSelectedKey={setSelectedKey}
              />
            </ProtectedRouterWrapper>
          </div>
        </Row>
      </Col>
    </Fragment>
  );
};

export default RouteMain;
