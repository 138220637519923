import { type UseQueryResult, useQuery } from 'react-query';
import { ProfileService, type Volo_Abp_Account_ProfileDto } from '../../services/codex-services';

const useGetUserProfile = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  isAuthenticated: boolean
): UseQueryResult<Volo_Abp_Account_ProfileDto | undefined, unknown> => {
  return useQuery(
    ['getUserProfile'],
    async () => {
      return await handleRequest(ProfileService.getCoreApiApiAccountMyProfile());
    },
    {
      enabled: !!isAuthenticated,
    }
  );
};

export default useGetUserProfile;
