import { ConfigProvider, Modal, ThemeConfig } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { selectToken, setCredentials } from './auth/authSlice';
// import theme from './constants/style/theme';
import useCodexApi from './hooks/useCodexApi';
import { setRouteList } from './pages/configuration/routeSlice';
import { AbpApplicationConfigurationService } from './services/codex-services';
import { getFilteredPermissionData } from './utils';

import { useNavigate } from 'react-router-dom';
import { REDIRECT_PERMISSION_MESSAGE, RELOAD_PERMISSION_MESSAGE } from './constants/common';
import RouteMain from './pages';
import AuthService from './services/authService';
import GlobalStyles from './globalStyles.ts';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean | null>(null);
  const [themeUnits, setThemeUnits] = useState({
    fontSize: 16,
    sizeUnit: 4,
    sizeStep: 4,
    menu: {
      collapsedIconSize: 16,
      collapsedWidth: 80,
      dropdownWidth: 160,
      iconSize: 14,
      itemHeight: 40,
      itemPaddingInline: 16,
      itemMarginInline: 4,
    },
    Table: {
      cellPaddingBlock: 16,
    },
  });

  const authService = new AuthService();
  const token = useSelector(selectToken);
  const { handleRequest } = useCodexApi();

  useEffect(() => {
    setLoading(true);
    if (!authService.isAuthenticated()) {
      setLoading(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    authService
      .getUser()
      .then((user) => {
        if (user != null) {
          setLoading(true);
          dispatch(
            setCredentials({
              isAuthenticated: authService.isAuthenticated(),
              token: authService.getToken(),
              user,
            })
          );
        }
      })
      .catch((err) => {
        if (err == null) void authService.signOutRedirectCallback();
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token?.length > 0) {
      handleRequest(AbpApplicationConfigurationService.getCoreApiApiAbpApplicationConfiguration())
        .then((response) => {
          const nonFieldLevelPermissionsGroups = getFilteredPermissionData(
            response?.auth?.grantedPolicies
          );
          const RouteList = Object.keys(nonFieldLevelPermissionsGroups).map((item) => item);
          dispatch(
            setRouteList({
              currentUserAuthList: response ?? {},
              currentUserRoutesList: RouteList ?? [],
            })
          );
        })
        .catch((err) => {
          console.log('err', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  window.addEventListener('PERMISSION_CHANGED', (evt: any) => {
    openModal(evt.detail);
  });

  const openModal = (permissionStatus: string) => {
    Modal.info({
      title: 'Alert',
      content: (
        <div>
          <p>
            {permissionStatus === 'RELOAD'
              ? RELOAD_PERMISSION_MESSAGE
              : REDIRECT_PERMISSION_MESSAGE}
          </p>
        </div>
      ),
      onOk() {
        if (permissionStatus === 'SIGN_IN') {
          Modal.destroyAll();
          setLoading(true);
          authService.signInSilent();
        } else if (permissionStatus === 'REFRESH') {
          Modal.destroyAll();
          setLoading(true);
          navigate(0);
          navigate('/');
        } else {
          Modal.destroyAll();
          setLoading(true);
          navigate(0);
        }
      },
    });
  };

  const updateTheme = () => {
    if (window.innerWidth < 1024) {
      setThemeUnits({
        ...themeUnits,
        fontSize: 14,
        sizeUnit: 3,
        sizeStep: 3,
        menu: {
          collapsedIconSize: 14,
          collapsedWidth: 44,
          dropdownWidth: 50,
          iconSize: 14,
          itemHeight: 28,
          itemPaddingInline: 11,
          itemMarginInline: 2,
        },
        Table: {
          cellPaddingBlock: 8,
        },
      });
    }
  };

  useEffect(() => {
    // updateFontSize(); // Set initial font size
    updateTheme();
  }, []);

  const theme: ThemeConfig = {
    token: {
      fontFamily: 'Calibri',
      fontSize: themeUnits.fontSize,
      colorPrimary: '#172e55',
      colorInfo: '#172e55',
      borderRadius: 5,
      wireframe: true,
      boxShadowSecondary:
        '\n      0 5px 12px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
      colorWarning: '#f26923',
      sizeUnit: themeUnits.sizeUnit,
      sizeStep: themeUnits.sizeStep,
    },
    components: {
      Menu: {
        controlItemBgActive: 'rgba(139, 144, 148, 0.42)',
        collapsedIconSize: themeUnits.menu.collapsedIconSize,
        collapsedWidth: themeUnits.menu.collapsedWidth,
        dropdownWidth: themeUnits.menu.dropdownWidth,
        iconSize: themeUnits.menu.iconSize,
        itemHeight: themeUnits.menu.itemHeight,
        itemPaddingInline: themeUnits.menu.itemPaddingInline,
        itemMarginInline: themeUnits.menu.itemMarginInline,
      },
      Input: {
        activeShadow: '0 0 0 1px rgba(5, 145, 255, 0.1)',
      },
      Table: {
        headerBg: '#172e55',
        headerColor: '#FFFFFF',
        headerSortHoverBg: '#172e55',
        headerSortActiveBg: '#172e55',
        cellPaddingBlock: themeUnits.Table.cellPaddingBlock,
      },
    },
  };

  return (
    <>
      <ConfigProvider theme={theme}>
        <GlobalStyles />
        <RouteMain loading={loading} />
        {/* {loading === null || loading ? (
          <Spin
            tip='Loading....'
            size='large'
            fullscreen
            style={{ backgroundColor: theme?.token?.colorPrimary }}>
            <div className='content' />
          </Spin>
        ) : (
          <RouteMain />
        )} */}
      </ConfigProvider>
    </>
  );
}

export default App;
