import type { MenuProps } from 'antd';
import { Flex, Menu } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SIDEBAR_ROUTES } from '../../constants/common';
import { selectAuthRoutesList } from '../../pages/configuration/routeSlice';
import {
  addStaticRoutes,
  checkChildrenRoute,
  checkRoutes,
} from '../../utils/permission/checkRoute';
import { SideNavWrapper } from './sidenav.styled';

type MenuItem = Required<MenuProps>['items'][number];

interface SidebarType {
  collapsed: boolean;
  selectedKey: string;
}

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
};

const generateMenuItems = (items: any[], userAuthList: any): MenuItem[] => {
  return items.map((item) => {
    if (item.children && item.children.length > 0) {
      // If the item has children, call the function recursively

      const CheckSidebar = checkChildrenRoute(item?.pathname, item.children, userAuthList);

      const subMenu = generateMenuItems(CheckSidebar, userAuthList);
      return getItem(
        <Link to={item.route}>{item.name}</Link>,
        `${item.pathname}`,
        item.icon,
        subMenu
      );
    } else {
      // If the item has no children, generate a regular menu item
      return getItem(<Link to={item.route}>{item.name}</Link>, `${item.pathname}`, item.icon);
    }
  });
};

const Items = (userAuthList: any) => {
  const sidebarItems = checkRoutes(SIDEBAR_ROUTES, userAuthList);
  const allSidebarItems = addStaticRoutes(SIDEBAR_ROUTES, sidebarItems);
  return generateMenuItems(allSidebarItems, userAuthList);
};

const Sidebar = ({ collapsed, selectedKey }: SidebarType) => {
  const userAuthList = useSelector(selectAuthRoutesList);

  return (
    <SideNavWrapper>
      <Flex
        style={{
          height: '100%',
        }}>
        <Menu
          selectedKeys={[selectedKey]}
          mode='inline'
          inlineCollapsed={collapsed}
          items={Items(userAuthList)}
        />
      </Flex>
    </SideNavWrapper>
  );
};

export default Sidebar;
