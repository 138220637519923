import { Spin, theme } from 'antd';

const { useToken } = theme;
interface IPageLoadingProps {
  tip?: string;
  intensity?: 'high' | 'medium' | 'low';
}
const PageLoading = ({ tip }: IPageLoadingProps) => {
  const { token } = useToken();
  return (
    <Spin
      tip={tip || 'Loading....'}
      size='large'
      fullscreen
      style={{ backgroundColor: token?.colorPrimary }}>
      <div className='content' />
    </Spin>
  );
};

export default PageLoading;
