import _ from 'lodash';
import { PERMISSION_LIST } from '../constants/common';
import { Tag } from 'antd';
import { COLORS } from '../constants/style/colors';

export const alphanumericaAndSpecialChar =
  /^(?!.*['\-_!@#$%^*&\[\]().:;'"/, \n’ʹʺʻʼʽˮ]{4})[-A-Za-z0-9_!@#$%^*&\[\]().:;'"/, \n’ʹʺʻʼʽˮ]+(?:\s,\s?[-A-Za-z0-9_!@#$%^*&\[\]().:;'"/, \n’ʹʺʻʼʽˮ]+)*$/;

// Converts Strings Data with value to groups objects
const getCustomerPolicies = (policies: Record<string, boolean>): string[] =>
  Object.keys(policies)
    .map((item) => {
      return item;
    })
    .filter((item) => {
      const permission = PERMISSION_LIST.map((item) => item.id);
      return permission.includes(item.split('.')[0]);
    });

export const restructureNonFieldPermissions = (data: string[]) => {
  return data?.map((item: string) => {
    const permissionParts = item.split('.');
    return {
      groupKey: permissionParts[0],
      key: item,
      text: permissionParts.length > 1 ? permissionParts[1] : 'View',
    };
  });
};

export const getFilteredPermissionData = (policies?: Record<string, boolean> | null) => {
  const customerPolicies = getCustomerPolicies(policies ?? {});

  const nonFieldLevelPermissionsData = restructureNonFieldPermissions(customerPolicies);

  const nonFieldLevelPermissionsGroups = _.groupBy(
    nonFieldLevelPermissionsData,
    (item: { groupKey: string; key: string; text: string }) => item.groupKey
  );

  return nonFieldLevelPermissionsGroups;
};

export const renderStatusTag = (status: string) => {
  if (status === 'Rejected') {
    return <Tag color={COLORS.Red}>{status}</Tag>;
  } else if (status === 'Approved') {
    return <Tag color={COLORS.Green}>{status}</Tag>;
  } else {
    return <Tag color={COLORS.Silver}>{status}</Tag>;
  }
};

export const renderActiveTag = (isActive: boolean) => {
  if (isActive) {
    return <Tag color={COLORS.Green}>Active</Tag>;
  } else {
    return <Tag color={COLORS.SpanishGray}>Inactive</Tag>;
  }
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Function to render role tags
export const renderRoleTags = (roles: any[]) => (
  <span>
    {roles?.map((role: any, i: number) => {
      let color = role.roleName.length > 5 ? 'geekblue' : 'green';
      if (role.roleName === 'admin') {
        color = 'volcano';
      }

      // Capitalize the first letter of roleName
      const capitalizedRoleName = capitalizeFirstLetter(role.roleName);

      return (
        <Tag color={color} key={i}>
          {capitalizedRoleName}
        </Tag>
      );
    })}
  </span>
);
export const runValidators =
  (fieldName: string, isRequired: boolean = false) =>
  async (rule: any, value: any): Promise<void> => {
    const trimmedValue = typeof value === 'string' ? value.trim() : value;

    return new Promise((resolve, reject) => {
      if (
        isRequired &&
        (trimmedValue === undefined || trimmedValue === null || trimmedValue === '')
      ) {
        reject(`${fieldName} is required`);
      } else if (trimmedValue && !/^[A-Za-z\s]+$/.test(trimmedValue)) {
        reject(`${fieldName} should only contain alphabetic characters`);
      } else if (trimmedValue && trimmedValue.length < 3) {
        reject(`${fieldName} must have at least 3 characters`);
      } else if (trimmedValue && trimmedValue.length > 32) {
        reject(`${fieldName} must not exceed 32 characters`);
      } else {
        resolve(); // Indicates that validation passed
      }
    });
  };

export const runValidatorsDesc =
  (fieldName: string, isRequired: boolean = false) =>
  async (rule: any, value: any): Promise<void> => {
    const trimmedValue = typeof value === 'string' ? value.trim() : value;

    return new Promise((resolve, reject) => {
      if (
        isRequired &&
        (trimmedValue === undefined || trimmedValue === null || trimmedValue === '')
      ) {
        reject(`${fieldName} is required`);
      } else if (trimmedValue && !alphanumericaAndSpecialChar.test(trimmedValue)) {
        reject(`${fieldName} invalid`);
      } else if (trimmedValue && trimmedValue.length < 3) {
        reject(`${fieldName} must have at least 3 characters`);
      } else if (trimmedValue && trimmedValue.length > 200) {
        reject(`${fieldName} must not exceed 200 characters`);
      } else {
        resolve(); // Indicates that validation passed
      }
    });
  };

export const runValidatorsForCodeX =
  (fieldName: string, isRequired: boolean = false) =>
  async (rule: any, value: any): Promise<void> => {
    const trimmedValue = typeof value === 'string' ? value.trim() : value;

    return new Promise((resolve, reject) => {
      if (
        isRequired &&
        (trimmedValue === undefined || trimmedValue === null || trimmedValue === '')
      ) {
        reject(`${fieldName} is required`);
      } else {
        // Remove spaces from the value for validation
        const valueWithoutSpaces = trimmedValue.replace(/\s/g, '');

        if (valueWithoutSpaces && !/^[A-Za-z0-9]+$/.test(valueWithoutSpaces)) {
          reject(`${fieldName} should only contain alphanumeric characters`);
        } else if (valueWithoutSpaces && valueWithoutSpaces.length < 2) {
          reject(`${fieldName} must have at least 2 characters`);
        } else if (valueWithoutSpaces && valueWithoutSpaces.length > 32) {
          reject(`${fieldName} should not exceed 32 characters`);
        } else {
          resolve(); // Indicates that validation passed
        }
      }
    });
  };

export const trimValues = (values: Record<string, any>): Record<string, any> => {
  const trimmedValues: Record<string, any> = {};

  Object.keys(values).forEach((key) => {
    if (typeof values[key] === 'string') {
      trimmedValues[key] = values[key].trim();
    } else {
      trimmedValues[key] = values[key];
    }
  });

  return trimmedValues;
};
export const validateAlphanumeric =
  (fieldName: string) =>
  (rule: any, value: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (value === undefined || value === null || value.trim() === '') {
        reject(`${fieldName} is required`);
      } else if (!/^[A-Za-z0-9]+$/.test(value)) {
        reject(`${fieldName} should only contain alphanumeric characters`);
      } else {
        resolve(); // Indicates that validation passed
      }
    });
  };
