import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/authService';
import { Spin } from 'antd';
import PageLoading from '../components/loader/Loading';

const Callback = (): JSX.Element => {
  const navigate = useNavigate();
  const authService = new AuthService();

  useEffect(() => {
    if (!authService.isAuthenticated()) {
      authService.signInRedirectCallback();
    } else {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PageLoading />;
};

export default Callback;
